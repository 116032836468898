<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="LinkIcon" size="18" />
            <h4 class="mb-0 ml-75">Réseaux Sociaux</h4>
          </div>
        </b-col>

        <!-- twitter -->
        <b-col md="6">
          <b-form-group label-for="account-twitter" label="Twitter">
            <b-form-input
              id="account-twitter"
              v-model="twitter"
              placeholder="Ajouter lien twitter"
            />
          </b-form-group>
        </b-col>
        <!--/ twitter -->

        <!-- facebook -->
        <b-col md="6">
          <b-form-group label-for="account-facebook" label="Facebook">
            <b-form-input
              id="account-facebook"
              v-model="facebook"
              placeholder="Ajouter lien vers la page facebook"
            />
          </b-form-group>
        </b-col>
        <!--/ facebook -->

        <!-- google+ -->
        <b-col md="6">
          <b-form-group label-for="account-google" label="Google+">
            <b-form-input
              id="account-google"
              v-model="googleplus"
              placeholder="Ajouter lien vers google+"
            />
          </b-form-group>
        </b-col>
        <!--/ google+ -->

        <!-- linkedin -->
        <b-col md="6">
          <b-form-group label-for="account-linkedin" label="LinkedIn">
            <b-form-input
              id="account-linkedin"
              v-model="linkedin"
              placeholder="Ajouter lien linkedin"
            />
          </b-form-group>
        </b-col>
        <!-- linkedin -->

        <b-col cols="12">
          <hr class="my-2" />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="postCorporationSettingSocialForm"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Annuler
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.socialData)),
      sku_corporation: userData.sku_corporation,
      facebook: JSON.parse(JSON.stringify(this.socialData)).facebook,
      twitter: JSON.parse(JSON.stringify(this.socialData)).twitter,
      googleplus: JSON.parse(JSON.stringify(this.socialData)).googleplus,
      linkedin: JSON.parse(JSON.stringify(this.socialData)).linkedin,
      errors: [],
    };
  },
  methods: {
    async postCorporationSettingSocialForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("facebook", this.facebook);
      formData.append("twitter", this.twitter);
      formData.append("googleplus", this.googleplus);
      formData.append("linkedin", this.linkedin);
      //
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData));
    },
  },
};
</script>
