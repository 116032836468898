<template>
  <div>

    <strong>Chargement >> ...</strong>

    <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-if="options.general"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Informations Génerales</span>
      </template>

      <corporation-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Documents Officiels</span>
      </template>

      <corporation-setting-official
        v-if="options.official"
        :official-data="options.official"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Autres Informations</span>
      </template>

      <corporation-setting-information
        v-if="options.information"
        :information-data="options.information"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Web & Social</span>
      </template>

      <corporation-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <corporation-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import CorporationSettingGeneral from "@/views/administration/corporation/CorporationSettingGeneral.vue";
import CorporationSettingOfficial from "@/views/administration/corporation/CorporationSettingOfficial.vue";
import CorporationSettingInformation from "@/views/administration/corporation/CorporationSettingInformation.vue";
import CorporationSettingSocial from "@/views/administration/corporation/CorporationSettingSocial.vue";
import CorporationSettingNotification from "@/views/administration/corporation/CorporationSettingNotification.vue";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
//

export default {
  components: {
    BTabs,
    BTab,
    CorporationSettingGeneral,
    CorporationSettingOfficial,
    CorporationSettingInformation,
    CorporationSettingSocial,
    CorporationSettingNotification,
  },
  data() {
    return {
      sku_corporation: "SKU-001", // SKU-001
      options: {},
    };
  },
  methods: {
    async getCorporationInfos() {
      // //
      const final_response = await axios
        .get(
          $themeConfig.app.api_endpoint +
            `get_corporation_infos/${$themeConfig.app.api_endpoint_access_token}/${this.sku_corporation}`
        )
        .then((response) => {
          var corporation_data = response.data.corporationInfos;
          var corporation_data_logo = response.data.corporationLogo;
    
          const corporationDataCollection = {
            ////////////////////////////////////////
            general: {
              corporation_name: corporation_data.corporation_name,
              corporation_name_mini: corporation_data.corporation_name_mini,
              logo_url: corporation_data_logo,
              ceo: corporation_data.ceo,
              phone: corporation_data.phone,
              email: corporation_data.email,
              country: corporation_data.country,
              state: corporation_data.state,
              town: corporation_data.town,
              adresse: corporation_data.adresse,
              employee_capacity: corporation_data.employee_capacity,
              state: corporation_data.state,
            },
            official: {
              legal: corporation_data.legal,
              idnat: corporation_data.idnat,
              rccm: corporation_data.rccm,
              impot: corporation_data.impot,
            },
            information: {
              description: corporation_data.description,
              slogan: corporation_data.slogan,
            },
            notification: {
              alert_sale: corporation_data.alert_sale,
              alert_expense: corporation_data.alert_expense,
              alert_payment: corporation_data.alert_payment,

              alert_non_attendance: corporation_data.alert_non_attendance,
              alert_loss: corporation_data.alert_loss,
              alert_stock: true, // corporation_data.alert_stock,
              alert_expiration: corporation_data.alert_expiration,

              alert_sms: corporation_data.alert_sms,
              alert_email: corporation_data.alert_email,
              alert_contact: corporation_data.alert_contact,
              alert_fake_account: corporation_data.alert_fake_account,
            },
            social: {
              twitter: corporation_data.twitter,
              facebook: corporation_data.facebook,
              googleplus: corporation_data.googleplus,
              linkedin: corporation_data.linkedin,
            },
          };
          this.options = corporationDataCollection;
          // console.log(this.options);
        })
        .catch((error) => {
          console.log(error);
        });
      return final_response;
    },
  },
  beforeCreate() {
    // local get
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
  created() {
    // get from web server
    this.getCorporationInfos();
  },
};
</script>
